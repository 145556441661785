import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Store } from 'stores'

import Modal from 'react-bootstrap/Modal'

import { sendContacts } from '../../tools/SendContacts'

import './styles.css'

interface IInjectedProps {
  store: Store
}

@inject('store')
@observer
class FormComponent extends Component {
  state = {
    name: '',
    phone: '',
    email: '',
    message: '',
    pot: '',
    sendForm: false,
    isOpen: false,
  }

  openModal() {
    this.setState({
      isOpen: true,
    })
  }

  closeModal() {
    this.setState({
      isOpen: false,
    })
  }
  changHandler = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  async submitData(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const result = await sendContacts({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      pot: this.state.pot,
    })
    if (result === false) {
      this.setState({
        sendForm: false,
      })
    } else {
      this.setState({
        sendForm: true,
      })
      this.setState({
        name: '',
        phone: '',
        message: '',
        pot: '',
        email: '',
      })
    }
    this.openModal()
  }

  render() {
    const { sendText, sendError, setIsFocus, setIsNotFocus, meditation } = (this
      .props as IInjectedProps).store
    return (
      <div id="contact" className="panel position-relative">
        <div className="panel-text ">
          <h2>{meditation.h2}</h2>
          <form onSubmit={(e) => this.submitData(e)} className="form">
            <div className="form-wrap">
              <div className="form-column">
                <input
                  required
                  onFocus={setIsFocus}
                  onBlur={setIsNotFocus}
                  onChange={this.changHandler}
                  type="text"
                  className="input input-mb"
                  name="name"
                  placeholder={meditation.placeholder1}
                  value={this.state.name}
                />
                <input
                  onFocus={setIsFocus}
                  onBlur={setIsNotFocus}
                  type="tel"
                  className="input input-mb"
                  placeholder={meditation.placeholder2}
                  name="phone"
                  value={this.state.phone}
                  onChange={this.changHandler}
                />

                <input
                  required
                  onFocus={setIsFocus}
                  onBlur={setIsNotFocus}
                  type="email"
                  className="input input-mb-last"
                  placeholder="E-mail"
                  name="email"
                  value={this.state.email}
                  onChange={this.changHandler}
                />
              </div>
              <div className="message">
                <textarea
                  onFocus={setIsFocus}
                  onBlur={setIsNotFocus}
                  className="input input-message"
                  name="message"
                  placeholder={meditation.placeholder3}
                  value={this.state.message}
                  onChange={this.changHandler}
                />
              </div>
            </div>

            <div className="control-input">
              <input
                type="text"
                name="pot"
                className="control-input"
                placeholder="Фамилия"
                value={this.state.pot}
                onChange={this.changHandler}
              />
            </div>
            <button type="submit" className="btn-form mt-2">
              <div className="btn-form-wrap">{meditation.button}</div>
            </button>
          </form>
        </div>
        <Modal show={this.state.isOpen} onHide={() => this.closeModal()}>
          <Modal.Header closeButton>
            <p className=" modal-text">
              {this.state.sendForm ? sendText : sendError}
            </p>
          </Modal.Header>
        </Modal>
      </div>
    )
  }
}

export default FormComponent
