import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'
import Routes from 'routes'
import { Provider } from 'mobx-react'
import { stores } from 'stores'

function App() {
  return (
    <Provider {...stores}>
      <Routes />
    </Provider>
  )
}

export default App
