import { inject, observer } from 'mobx-react'
import React, { Component, WheelEvent, TouchEvent } from 'react'
import { Store } from 'stores'
import { action, reaction } from 'mobx'
import { MeditationText, PossibilityContent } from 'interfaces/Interfaces'
import ScrollComponent from 'components/screenWrapper/ScrollComponent'
import ScreensComponent from 'components/screensComponent/ScreensComponent'
import Header from 'components/Header'
import FooterMenu from 'components/FooterMenu'
import { ReviewsText } from 'interfaces/Interfaces'
import { on } from 'process'
// interface Props {
//   language: language
// }

interface IInjectedProps {
  store: Store
}

const en: MeditationText = {
  welcom: 'MY ENERGY STREAM',
  welcom2: 'Effective digital receptive music therapy tool',
  possibility: 'Opportunities',
  download: 'Download',
  p1:
    'How long have you been relaxing, falling asleep or recovering without pills?',
  p2:
    'Every day, various negative factors affect us, causing stress and psychosomatic disorders. One in seven people experience regular headaches, and one in four has trouble sleeping.',
  p3:
    'The My Energy Stream app is a unique digital receptive music therapy tool with clinically proven effectiveness.** ',
  p3tooltip: '*Source: Article in Trad.med. 2022 journal Shushardzhan, S.V.',
  p4:
    'The compositions have a pronounced relaxing effect, alleviating various forms of stress:',
  p4ul:
    '<li>headache</li><li>insomnia</li><li>anxiety</li><li>panic attacks</li><li>psychosomatic reactions</li>',
  p5:
    'Regular listening gradually relieves anxiety, fears and psychosomatic disorders, and increases the tone, performance and the energy potential of the body.',
  h2: 'Contact Us',

  placeholder1: 'Name',
  placeholder2: 'Phone',
  placeholder3: 'Message',
  button: 'Send',
  install: 'You can install the App on your device right now!',
  rewiews: 'Users feedback',
}

const ru: MeditationText = {
  welcom: 'MY  ENERGY STREAM',
  welcom2: 'Эффективный инструмент цифровой рецептивной музыкотерапии',
  possibility: 'Возможности',
  download: 'Скачать',
  p1: 'Как давно вы расслаблялись, засыпали и восстанавливались без таблеток?',
  p2:
    'Ежедневно различные негативные факторы влияют на нас, вызывая стресс и психосоматические расстройства: каждый седьмой испытывает регулярные головные боли, каждый четвертый — проблемы со сном. ',
  p3:
    'Приложение My Energy Stream — уникальный инструмент цифровой рецептивной музыкотерапии, эффективность которого клинически доказана**.',
  p3tooltip: '*Источник: Статья в журнал Трад.мед. 2022 Шушарджан С.В.',
  p4:
    'Композиции обладают выраженным расслабляющим эффектом, облегчая различные проявления стресса:',
  p4ul:
    '<li>головная боль</li><li>панические атаки</li><li>бессонница</li><li>тревога</li><li>психосоматические реакции</li>',
  p5:
    'Регулярное прослушивание постепенно избавляет от тревожности, страхов и психосоматических расстройств, повышает тонус, работоспособность и энергетический потенциал организма.',
  h2: 'Связаться с нами',

  placeholder1: 'Имя',
  placeholder2: 'Телефон',
  placeholder3: 'Сообщение',
  button: 'Отправить',
  install: 'Вы можете установить приложение на свое устройство прямо сейчас!',
  rewiews: 'Отзывы пользователей',
}

const enPos: PossibilityContent[] = [
  {
    button: 'Features',
    id: 2,
    content: {
      img: '/img/space.svg',
      active: false,
      item:
        '<p>My Energy Stream has 82 ready-to-use programs, based on musical-acoustic S-algorithms. They reduce levels of stress hormones and nervous excitability, resulting in a noticeable improvement in sleep quality, and reduced frequency of headaches, anxiety, panic attacks, etc.</p><p>My Energy Stream compositions can be listened to by people of any age and gender without any health restrictions. The greatest benefits are felt by socially active people engaged in intellectual work.</p>',
    },
  },
  {
    button: 'Design',
    id: 1,
    content: {
      img: '/img/space.svg',
      active: true,
      item:
        '<p>The app is the Universe in miniature: compositions in the form of stars are collected on 11 playlist planets. 3D images and space flights relax and help you tune in to the recovery process.</p><p>Music can be listened to online and offline. There are 4 x 5-hour online streams times a day, which interact more with the body and trigger deep renewal processes.</p><p>The app also has a general chat room where you can share your experiences, ask questions, and obtain advice from a psychologist.</p>',
    },
  },
  {
    button: 'Gift',
    id: 4,
    content: {
      img: '/img/space.svg',
      active: false,
      item:
        '<p>The app has a free basic version and a paid VIP subscription. You get the first 7 days extra when you pay for VIP access.</p>',
    },
  },
]

const ruPos: PossibilityContent[] = [
  {
    button: 'Особенности',
    id: 2,
    content: {
      img: '/img/space.svg',
      active: false,
      item:
        '<p>My Energy Stream — это 82 готовые программы, в основе которых лежат музыкально-акустические S-алгоритмы. Они снижают уровень гормонов стресса и нервную возбудимость, в результате чего заметно улучшается качество сна, уменьшается жалобы на головные боли, тревожность, панические атаки и т.д. </p><p>Слушать композиции My Energy Stream могут люди любого возраста и пола без ограничений по состоянию здоровья. Наибольшую пользу ощущают социально-активные люди, занимающиеся интеллектуальным трудом.</p>',
    },
  },
  {
    button: 'Дизайн',
    id: 1,
    content: {
      img: '/img/space.svg',
      active: true,
      item:
        '<p>Приложение — Вселенная в миниатюре: композиции в виде звезд собраны на 11 планетах-плейлистах. Трехмерные образы и космические полеты расслабляют и помогают настроиться на процесс восстановления. </p> <p>Слушать музыку можно в режиме онлайн и офлайн. 4 раза в день проходят 5-часовые онлайн стримы: они сильнее взаимодействуют с организмом и запускают глубинные процессы обновления.</p><p>Также в приложении есть общий чат, где можно делиться своим опытом, задать вопросы и получить консультацию психолога. </p>',
    },
  },

  {
    button: 'Подарок',
    id: 4,
    content: {
      img: '/img/space.svg',
      active: false,
      item:
        '<p>Приложение имеет бесплатную базовую версию и платную VIP-подписку. При оплате VIP-доступа вы получаете первые 7 дней дополнительно.</p>',
    },
  },
]

const enReview: ReviewsText[] = [
  {
    id: 1,
    img: '/img/avatar1.jpg',
    name: 'Marina',
    active: true,
    item:
      'Enjoyable and soothing music. I like to use it in my meditations before going to sleep: I fall asleep quickly and soundly, like a baby.',
  },
  {
    id: 2,
    img: '/img/avatar2.jpg',
    name: 'Ruslan',
    active: false,
    item:
      'I have never believed in such a thing before. I had been suffering from migraines for seven years. My friends persuaded me to try it. Suddenly, I felt relief and relaxation after the streaming session. The pain began to recede! Now I connect to the stream regularly. Thank you!',
  },
  {
    id: 3,
    img: '/img/avatar3.jpg',
    name: 'Alina',
    active: false,
    item:
      "In the city, this recharging is very helpful. At any time, I turn on the music, 5 minutes, and I'm breathing peacefully again. I often use it on the subway instead of just scrolling through the news feed on the way home. I get home as if I am rested.",
  },
  {
    id: 4,
    img: '/img/avatar4.jpg',
    name: 'Jurij',
    active: false,
    item:
      "The tracks are very balancing, relaxing. I have connected to the chat, and now it's clearer what kind of warmth I feel inside me while listening and how you can control the flow of energy in yourself. It's cool.",
  },
  {
    id: 5,
    img: '/img/avatar5.jpg',
    name: 'Regina',
    active: false,
    item:
      'I have been using the App for three months, 20 minutes a day. I feel that the energy has increased, I get better sleep, and more time in a day, the muscle aches have disappeared. As a mother, it is essential to me. Thank you so much!',
  },
]

const ruReview: ReviewsText[] = [
  {
    id: 1,
    img: '/img/avatar1.jpg',
    name: 'Марина',
    active: true,
    item:
      'Очень приятная и успокаивающая музыка. Люблю её использовать в своих медитациях перед сном: потом засыпаю легко и крепко, как младенец',
  },
  {
    id: 2,
    img: '/img/avatar2.jpg',
    name: 'Руслан',
    active: false,
    item:
      'Раньше не верил в такое. Семь лет мучался с мигренью, друзья уговорили попробовать, и внезапно я ощутил после стрим-сеанса облегчение, расслабление. Боли стали отступать! Теперь подключаюсь регулярно. Спасибо',
  },
  {
    id: 3,
    img: '/img/avatar3.jpg',
    name: 'Алина',
    active: false,
    item:
      'В городе такая перезарядка очень помогает: в любой момент я включаю музыку, 5 минут и я снова спокойно дышу. Часто использую в метро по дороге домой, вместо пустого листания ленты. Домой приезжаю будто отдохнувшая',
  },
  {
    id: 4,
    img: '/img/avatar4.jpg',
    name: 'Юрий',
    active: false,
    item:
      'Треки очень уравновешивают, расслабляют. Подключился к чату, теперь стало понятнее, что за тепло я ощущаю внутри себя во время прослушивания, и как можно управлять потоком энергии в себе. Это круто',
  },
  {
    id: 5,
    img: '/img/avatar5.jpg',
    name: 'Регина',
    active: false,
    item:
      'Использую приложение уже 3 месяца, по 20 минут в день. По моим ощущениям, энергии действительно прибавилось — стала лучше высыпаться и больше успевать за день, исчезли мышечные боли. Как маме, мне это очень важно. Огромное спасибо!!',
  },
]
@inject('store')
@observer
class Main extends Component {
  myRef: React.RefObject<HTMLVideoElement>

  constructor(props: any) {
    super(props)
    this.myRef = React.createRef<HTMLVideoElement>()
  }

  updateLanguage() {
    const { store } = this.props as IInjectedProps
    if (store.language === 'ru') {
      store.setMeditation(ru)
      store.setPossibilityContent(ruPos)
      store.setReviewsText(ruReview)
    } else if (store.language === 'en') {
      store.setMeditation(en)
      store.setPossibilityContent(enPos)
      store.setReviewsText(enReview)
    }
  }

  componentDidMount() {
    const { store } = this.props as IInjectedProps
    const {
      store: { setWindowWidth },
    } = this.props as IInjectedProps

    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        setTimeout(() => {
          // tslint:disable-next-line:no-unused-expression
          this.myRef.current && this.myRef.current.play()
        }, 1000)
      }
    })

    window.addEventListener('focus', () => {
      setTimeout(() => {
        // tslint:disable-next-line:no-unused-expression
        this.myRef.current && this.myRef.current.play()
      }, 1000)
    })

    this.updateLanguage()

    reaction(
      () => store.language,
      (language) => {
        this.updateLanguage()
      },
    )
  }

  wheelEvent(event: WheelEvent) {
    const { scrollUp, scrollDown } = (this.props as IInjectedProps).store
    if (event.deltaY < 0) {
      scrollDown()
    }
    if (event.deltaY > 0) {
      scrollUp()
    }
    event.stopPropagation()
  }

  @action
  startEvent(event: TouchEvent) {
    const { setTouchStartY, setTouchStartX } = (this
      .props as IInjectedProps).store
    setTouchStartY(event.changedTouches[0].pageY)
    setTouchStartX(event.changedTouches[0].pageX)
  }

  moveEvent(event: TouchEvent) {
    // event.preventDefault()
  }

  @action
  endEvent(event: TouchEvent) {
    const { scrollUp, scrollDown } = (this.props as IInjectedProps).store
    let {
      touchPositionY,
      displayY,
      touchPositionX,
      displayX,
      touchStartY,

      touchStartX,
    } = (this.props as IInjectedProps).store
    touchPositionY = event.changedTouches[0].pageY
    touchPositionX = event.changedTouches[0].pageX
    displayX = touchPositionX - touchStartX
    displayY = touchPositionY - touchStartY

    if (Math.abs(displayY) > Math.abs(displayX)) {
      if (displayY > 0) {
        scrollDown()
      } else if (displayY < 0) {
        scrollUp()
      }
      event.stopPropagation()
    }
    touchStartY = 0
    touchPositionY = 0
    touchStartX = 0
    touchPositionX = 0
  }

  render() {
    return (
      <>
        <div
          className="main-element"
          onTouchStart={(event: TouchEvent) => this.startEvent(event)}
          onTouchMove={(event: TouchEvent) => this.moveEvent(event)}
          onTouchEnd={(event: TouchEvent) => this.endEvent(event)}
          onWheel={(event: WheelEvent) => this.wheelEvent(event)}
        >
          <div className="App-header">
            <video
              ref={this.myRef}
              disablePictureInPicture
              loop={true}
              className="video"
              autoPlay={true}
              muted={true}
              width="100%"
              height="auto"
              preload="auto"
              playsInline
            >
              <source
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                src="/img/SpaceVideoCorrected.mp4"
              />
              <source
                type='video/webm; codecs="vp8, vorbis"'
                src="/img/SpaceVideoCorrected.webm"
              ></source>
              <source
                src="/img/SpaceVideoCorrected.ogv"
                type='video/ogg; codecs="theora, vorbis"'
              ></source>
            </video>
            <Header />
            <div className="main-wrapper ">
              <div className=" container position-relative  main-item">
                <ScrollComponent />
                <ScreensComponent />
              </div>
            </div>

            <FooterMenu />
          </div>
        </div>
      </>
    )
  }
}

export default Main
