import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Carousel from 'react-bootstrap/Carousel'

import { images } from 'components/screens/screensData'

import './style.css'

interface IProps {
  singlePage?: boolean
}
// interface IInjectedProps extends IProps {
//   store: Store
// }

@inject('store')
@observer
class ScreensComponent extends Component<IProps> {
  render() {
    const { singlePage } = this.props

    const prevIcon = (
      <a>
        <span className="carousel-control-prev-icon">
          <svg
            className="carousel-control-prev-icon"
            width="14"
            height="21"
            viewBox="0 0 14 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3901 1.70728L2.63404 10.4878L12.3901 19.2683"
              stroke="currentColor"
              strokeWidth="3"
            />
          </svg>
        </span>
      </a>
    )

    const nextIcon = (
      <a>
        <span className="carousel-control-next-icon">
          <svg
            className="carousel-control-next-icon"
            width="14"
            height="21"
            viewBox="0 0 14 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.60986 1.70728L11.366 10.4878L1.60986 19.2683"
              stroke="currentColor"
              strokeWidth="3"
            />
          </svg>
        </span>
      </a>
    )

    return (
      <div
        className={`mainScreensWrapperImage ${
          singlePage && 'mainScreensSinglePageWrapperImage'
        }`}
      >
        <div className="screen-tel">
          <img src={'/img/bg-tel.png'} alt="" />
        </div>
        <div className="screen-wrapper">
          <Carousel
            fade={true}
            controls={true}
            indicators={false}
            interval={3000}
            prevIcon={prevIcon}
            nextIcon={nextIcon}
          >
            {images.map((item, index) => (
              <Carousel.Item key={index}>
                <img className="screen-image" src={item} alt="" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    )
  }
}

export default ScreensComponent
