import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import Main from 'pages/Main'
import store from 'stores/store'
import { language } from 'interfaces/Interfaces'

const currentLanguage = navigator.language.substr(0, 2)
store.setLanguage(currentLanguage as language)

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to={`/${currentLanguage}`} />
          </Route>

          <Route exact path="/promo/*">
            <Route component={() => <Main />} />
          </Route>

          <Route
            exact
            path="/en"
            component={() => {
              store.setLanguage('en')
              return <Main />
            }}
          />

          <Route
            exact
            path="/ru"
            component={() => {
              store.setLanguage('ru')
              return <Main />
            }}
          />
        </Switch>
      </Router>
    )
  }
}

export default Routes
