import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import Promo from 'components/screens/Promo'
import interfaces from 'interfaces'
import { Store } from 'stores'

import './style.css'

interface IInjectedProps {
  store: Store
}

@inject('store')
@observer
class ScrollComponent extends Component {
  render() {
    const { currentScreenId, windowWidth, windowHeight, screensData } = (this
      .props as IInjectedProps).store
    return (
      <div className="mainScreensWrapper ">
        {screensData.map((element, index) => {
          return (
            <div
              key={index}
              className={
                windowHeight < 481 &&
                windowWidth > windowHeight &&
                windowWidth > 400
                  ? ''
                  : 'screen ' +
                    (index === currentScreenId
                      ? 'screencurrent'
                      : index < currentScreenId
                      ? 'screenbefore'
                      : 'screenafter')
              }
            >
              {element.component}
            </div>
          )
        })}
      </div>
    )
  }
}

export default ScrollComponent
