export type language = 'en' | 'ru'

export interface Base {
  title: string
  description?: string
}

export interface Screen {
  title: string
  label: string
  labelEN: string
  id: string
  component: JSX.Element
}

export interface ScreensLabel {
  label: string
  id: string
}

// export interface DefaultProps {
//   // language?: language
// }
export interface MeditationText {
  welcom: string
  welcom2: string
  possibility: string
  download: string
  p1: string
  p2: string
  p3: string
  p3tooltip: string
  p4: string
  p4ul: string
  p5: string
  h2: string
  placeholder1: string
  placeholder2: string
  placeholder3: string
  button: string
  install: string
  rewiews: string
}

export interface PromoText {
  welcom: string
  welcom2: string
  p1: string
  buttonText: string
  p2: string
}

export interface PromoStoreText {
  welcom: string
  welcom2: string
  p1: string
}

export interface PossibilityContent {
  button: string
  id: number
  content: {
    img: any
    active: boolean
    item: string
  }
}

export interface ReviewsText {
  id: number
  img: any
  name: string
  active: boolean
  item: string
}
export interface FormText {
  h2: string
  placeholder1: string
  placeholder2: string
  placeholder3: string
  button: string
}

export enum Actions {
  notDetectedYet = 'notDetectedYet',
  redirectToAltDomainPromo = 'redirectToAltDomainPromo',
  showPromoLink = 'showPromoLink',
  showStoreLink = 'showStoreLink',
}
