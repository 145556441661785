import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Store } from 'stores'
import Social from './Social'
interface IInjectedProps {
  store: Store
}

@inject('store')
@observer
class FooterMenu extends Component {
  onElementClick(index: number) {
    const { setCurrentScreenId } = (this.props as IInjectedProps).store
    setCurrentScreenId(index)
  }

  render() {
    const { currentScreenActiveIndex, windowWidth, isFocus, language } = (this
      .props as IInjectedProps).store

    const { store } = this.props as IInjectedProps

    return (
      <div className="footer">
        {windowWidth < 768 && isFocus ? (
          <></>
        ) : (
          <div className="container position-relative">
            <Social />
            <nav className="navbar-nav footer-wrap">
              <div className="nav-footer-line" />
              <ul className="d-flex flex-row m-auto">
                {(language === 'ru'
                  ? store.screensLabelRU
                  : store.screensLabelEN
                ).map((screen, index) => (
                  <div
                    key={index}
                    className={`nav-footer-hover nav-footer-hover${index} ${
                      index <= currentScreenActiveIndex ? 'active' : ''
                    }`}
                    onClick={() => this.onElementClick(index)}
                  >
                    <li className={`nav-footer `} key={index}>
                      {screen.label}
                      <div
                        className={`nav-footer-circle nav-footer-circle${index}`}
                      />
                      <div className="nav-footer-line" />
                    </li>
                  </div>
                ))}
              </ul>
              <div className="nav-footer-line" />
            </nav>
          </div>
        )}
      </div>
    )
  }
}

export default FooterMenu
