import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Store } from 'stores'

import './styles.css'
interface IInjectedProps {
  store: Store
}

const medMain = 0

@inject('store')
@observer
class Possibility extends Component {
  componentDidMount() {}
  state = {
    activEl: 0,
  }
  changeBlock(index: number) {
    const { windowWidth, windowHeight } = (this.props as IInjectedProps).store
    this.setState({
      activEl: index,
    })
    console.log(windowWidth, windowHeight)
  }
  onElementClick(index: number) {
    const { setCurrentScreenId } = (this.props as IInjectedProps).store
    setCurrentScreenId(index)
  }

  handleScroll = (e: any) => {
    const { setIsScrollPos, setIsNotScrollPos } = (this
      .props as IInjectedProps).store
    setIsScrollPos()
    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight
    // e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    //  console.log({t:e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight})
    if (bottom) {
      console.log({ e: e.target.clientHeight })
      setIsNotScrollPos()
    }
  }
  render() {
    const { possibilityContent, meditation } = (this
      .props as IInjectedProps).store
    return (
      <div className="panel position-relative" id="possibility">
        <div className="panel-text ">
          <h2>{meditation.possibility}</h2>
          <div className=" possibility-block-btn">
            {possibilityContent.map((element, index: number) => (
              <div
                className={`possibility-btn ${
                  index === this.state.activEl ? 'active-btn' : ''
                }`}
                key={index}
              >
                <button
                  key={index}
                  onClick={() => this.changeBlock(index)}
                  className="btn-form"
                >
                  <div className="btn-form-wrap">{element.button}</div>
                </button>
              </div>
            ))}
            <div className="possibility-btn">
              <button
                onClick={() => this.onElementClick(medMain)}
                className="btn-form "
              >
                <div className="btn-form-wrap">
                  {meditation.download}
                  {/* {language === 'ru' ? 'Возможности' : 'Download'} */}
                </div>
              </button>
            </div>
          </div>
          <div
            className="possibility-wrap"
            // className="possibility-items"
            onScroll={this.handleScroll}
          >
            {possibilityContent.map((element, index: number) =>
              this.state.activEl === index ? (
                <div key={index} className="d-flex flex-row">
                  {/* <img
                    className="possibility-wrap-img"
                    src={element.content.img}
                    alt=""
                  /> */}
                  <div
                    dangerouslySetInnerHTML={{ __html: element.content.item }}
                  ></div>
                </div>
              ) : (
                <></>
              ),
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Possibility
