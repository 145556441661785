import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Store } from 'stores'
import Social from './Social'
interface IInjectedProps {
  store: Store
}
@inject('store')
@observer
class Header extends Component {
  state = {
    data: {},
    isMenuOpen: false,
  }

  componentDidMount() {
    const {
      store: { setWindowHeight },
    } = this.props as IInjectedProps
    // console.log(store.screensLabel[1].label)
    window.addEventListener('resize', () => setWindowHeight(window.innerHeight))
  }
  onElementClick(index: number) {
    const { setCurrentScreenId } = (this.props as IInjectedProps).store
    setCurrentScreenId(index)
  }
  toggleMenuMode() {
    this.setState({
      isMenuOpen: true,
    })
  }
  closeMenuMode() {
    this.setState({
      isMenuOpen: false,
    })
  }
  render() {
    const {
      currentScreenId,
      windowWidth,
      isFocus,
      windowHeight,
      language,
      isPromolink,
    } = (this.props as IInjectedProps).store

    const { store } = this.props as IInjectedProps

    return (
      <header
        className={`navbar ${windowWidth < 768 && isFocus ? 'header-abs' : ''}`}
      >
        <div className="container position-relative">
          <div className="logo">
            <img className="logo-img" src="/img/logo.png" alt="brand-logo" />
            <img
              className="logo-text"
              src="/img/logo-text.png"
              alt="brand-logo"
            />
          </div>
          <div className="navbar-lang">
            <li className={` ${language === 'en' ? 'active-lang' : ''}`}>
              {isPromolink && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault()
                    store.setLanguage('en')
                  }}
                >
                  En
                </a>
              )}
              {!isPromolink && <a href="/en">En</a>}
            </li>
            <li className={` ${language === 'ru' ? 'active-lang' : ''}`}>
              {isPromolink && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault()
                    store.setLanguage('ru')
                  }}
                >
                  Ru
                </a>
              )}
              {!isPromolink && <a href="/ru">Ru</a>}
            </li>
          </div>
          <button
            onClick={() => this.toggleMenuMode()}
            className={`navbar-toggler ${
              this.state.isMenuOpen ? 'nav-left' : ''
            }`}
            type="button"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <nav
            className={`navbar-header ${
              this.state.isMenuOpen ? 'nav-show' : ''
            }`}
          >
            <button
              onClick={() => this.closeMenuMode()}
              className="nav-my-close "
              type="button"
            >
              {/* <span className="navbar-toggler-my-close"> */}
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="17" stroke="white" strokeWidth="2" />
                <path d="M10 11L26 26" stroke="white" strokeWidth="2" />
                <path d="M26 11L10 26" stroke="white" strokeWidth="2" />
              </svg>
              {/* </span> */}
            </button>
            <ul className="d-flex flex-column">
              {(language === 'ru'
                ? store.screensLabelRU
                : store.screensLabelEN
              ).map((screen, index) => (
                <li
                  className={`nav-item ${
                    index === currentScreenId ? 'active' : ''
                  }`}
                  key={index}
                  onClick={() => this.onElementClick(index)}
                >
                  <div className="menu-item">
                    <svg
                      className="arrow"
                      width="28"
                      height="16"
                      viewBox="0 0 28 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.7071 8.70711C28.0976 8.31658 28.0976 7.68342 27.7071 7.29289L21.3431 0.928932C20.9526 0.538408 20.3195 0.538408 19.9289 0.928932C19.5384 1.31946 19.5384 1.95262 19.9289 2.34315L25.5858 8L19.9289 13.6569C19.5384 14.0474 19.5384 14.6805 19.9289 15.0711C20.3195 15.4616 20.9526 15.4616 21.3431 15.0711L27.7071 8.70711ZM0 9H27V7H0V9Z"
                        fill="white"
                      />
                    </svg>

                    {windowHeight > 480 ? (
                      <p>{screen.label}</p>
                    ) : (
                      // <p>{screensLabel[index].label}</p>
                      <a href={`#${screen.id}`}>
                        <p>{screen.label}</p>
                      </a>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <Social />
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
