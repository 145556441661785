import { observable, computed, action, makeAutoObservable, when } from 'mobx'
import interfaces from 'interfaces'
import {
  Actions,
  FormText,
  language,
  MeditationText,
  PossibilityContent,
  PromoStoreText,
  PromoText,
  ReviewsText,
  ScreensLabel,
} from 'interfaces/Interfaces'
import {
  PromoScreen,
  PromoStoreScreen,
  screensData,
  screensLabelEN,
  screensLabelPromoEN,
  screensLabelPromoRU,
  screensLabelRU,
} from 'components/screens/screensData'
import { altDomainName, domainName } from 'config'
import { Path } from 'path-parser'

export class Store {
  @observable
  fetchingState: 'initial' | 'fetching' | 'success' | 'error' = 'initial'

  @observable
  windowHeight: number = 0

  @observable
  widthWindow: number = 0

  @observable
  hold: boolean = false

  @observable
  language: language | undefined = undefined

  @observable
  promoLink?: string

  @observable
  meditation: MeditationText = {
    welcom: '',
    welcom2: '',
    possibility: '',
    download: '',
    p1: '',
    p2: '',
    p3: '',
    p3tooltip: '',
    p4: '',
    p4ul: '',
    p5: '',
    h2: '',
    placeholder1: '',
    placeholder2: '',
    placeholder3: '',
    button: '',
    install: '',
    rewiews: '',
  }
  @observable
  possibilityContent: PossibilityContent[] = [
    {
      button: '',
      id: 0,
      content: {
        img: '',
        active: false,
        item: '',
      },
    },
  ]
  @observable
  reviewsText: ReviewsText[] = [
    {
      id: 0,
      img: '',
      name: '',
      active: false,
      item: '',
    },
  ]
  @observable
  formText: FormText = {
    h2: '',
    placeholder1: '',
    placeholder2: '',
    placeholder3: '',
    button: '',
  }

  @computed
  get promoText(): PromoText {
    const promoTextEn: PromoText = {
      welcom: 'Thank you for using our promo code!',
      welcom2:
        'If you already have the application installed, then press the button to activate the promo code.',
      buttonText: 'Activate promo code',
      p1:
        'If you have not yet installed the application, then you can download it from the following links',
      p2: 'Then follow the link with the promo mode again.',
    }

    const promoTextRu: PromoText = {
      welcom: 'Спасибо что воспользовались нашим промокодом!',
      welcom2:
        'Если  у вас уже установлено приложение, то для активации промокода нажмите кнопку.',
      buttonText: 'Активировать промокод',
      p1:
        'Если вы еще не установили приложение то вы можете скачать его по следующим ссылкам',
      p2: 'После чего перейдите по ссылке с промодом еще раз.',
    }

    if (this.language === 'en') return promoTextEn
    else if (this.language === 'ru') return promoTextRu

    return promoTextEn
  }

  @computed
  get promoStoreText(): PromoStoreText {
    const promoStoreTextEn: PromoStoreText = {
      welcom: 'Thank you for using our promo code!',
      welcom2: 'Please download the app from the following links',
      p1: 'Then follow the link with the promo mode again.',
    }

    const promoStoreTextRu: PromoStoreText = {
      welcom: 'Спасибо что воспользовались нашим промокодом!',
      welcom2: 'Пожалуйста, скачайте приложение по следующим ссылкам',
      p1: 'После чего перейдите по ссылке с промодом еще раз.',
    }

    if (this.language === 'en') return promoStoreTextEn
    else if (this.language === 'ru') return promoStoreTextRu

    return promoStoreTextEn
  }

  @observable
  windowWidth: number = 0

  @observable
  activeElement: number = 0

  @observable
  step: number = 0

  @observable
  currentScreenId: number = 0

  @observable
  name: string = ''

  @observable
  sendText: string = 'Ваша заявка принята'

  @observable
  sendError: string = 'Произошла ошибка! Попробуйте позже.'

  @observable
  isFocus: boolean = false

  @observable
  isScroll: boolean = false

  @observable
  isScrollPos: boolean = false

  @observable
  threshold = 100

  @observable
  slack = 50

  @observable
  touchStartY: number = 0

  @observable
  avtoplay: boolean = true

  @observable
  touchStartX: number = 0

  @observable
  touchPositionY: number = 0

  @observable
  touchPositionX: number = 0

  @observable
  displayY: number | null = null

  @observable
  displayX: number | null = null

  @computed
  get screensData() {
    let screens = screensData(this.windowWidth)
    if (this.isPromolink && this.computeAction === Actions.showPromoLink) {
      screens = [PromoScreen, ...screens]
    }
    // if (this.isPromolink && this.computeAction === Actions.showStoreLink) {
    //   screens = [PromoStoreScreen, ...screens]
    // }
    return screens
  }

  @computed
  get screensLabelRU() {
    const screensLabelsRU: ScreensLabel[] = screensLabelRU(this.windowWidth)
    // if (this.isPromolink) {
    //   screensLabelsRU = [screensLabelPromoRU, ...screensLabelsRU]
    // }
    return screensLabelsRU
  }

  @computed
  get screensLabelEN() {
    const screensLabelsEN: ScreensLabel[] = screensLabelEN(this.windowWidth)
    // if (this.isPromolink) {
    //   screensLabelsEN = [screensLabelPromoEN, ...screensLabelsEN]
    // }
    return screensLabelsEN
  }

  @computed
  get currentScreenActiveIndex(): number {
    if (this.isPromolink) {
      return this.currentScreenId > 0 ? this.currentScreenId - 1 : 0
    } else {
      return this.currentScreenId
    }
  }

  @computed
  get currentScreen(): interfaces.Screen {
    return this.screensData[this.currentScreenId || 0]
  }

  @action
  setPromoLink = (newPromoLink: string) => {
    this.promoLink = newPromoLink
  }

  @computed
  get isPromolink() {
    const promoPath = new Path('/promo/:promo')
    const locationUrl = new URL(window.location.href)
    if (promoPath.test(locationUrl.pathname)) {
      return true
    }
    return false
  }

  @computed
  get computeAction() {
    const referrerUrl = document.referrer && new URL(document.referrer)
    const locationUrl = new URL(window.location.href)

    if (!this.isPromolink) {
      return Actions.notDetectedYet
    }

    if (locationUrl.host !== altDomainName) {
      return Actions.redirectToAltDomainPromo
    }

    if (locationUrl.host === altDomainName) {
      return Actions.showPromoLink
    }

    // if (!referrerUrl && referrerUrl.host === altDomainName) {
    //   return Actions.showStoreLink
    // }

    return Actions.notDetectedYet
  }

  log() {
    const locationUrl = new URL(window.location.href)
    const referrerUrl = document.referrer && new URL(document.referrer)
    // tslint:disable-next-line:no-console
    console.log({
      computeAction: this.computeAction,
      referrerUrl,
      locationUrl,
      rawReferrer: document.referrer,
    })
  }

  @computed
  get altDomainPromoLink() {
    const locationUrl = new URL(window.location.href)
    return `http://${altDomainName}${locationUrl.pathname}`
  }

  @computed
  get mainDomainPromoLink() {
    const locationUrl = new URL(window.location.href)
    return `http://${domainName}${locationUrl.pathname}`
  }

  @action
  setIsFocus = () => {
    this.isFocus = true
  }

  @action
  setLanguage = (value: language) => {
    this.language = value
  }

  @action
  setIsScroll = () => {
    this.isScroll = true
  }

  @action
  setMeditation = (value: MeditationText) => {
    this.meditation = value
  }

  @action
  setPossibilityContent = (value: PossibilityContent[]) => {
    this.possibilityContent = value
  }

  @action
  setFormText = (value: FormText) => {
    this.formText = value
  }

  @action
  setReviewsText = (value: ReviewsText[]) => {
    this.reviewsText = value
  }

  @action
  setIsNotScroll = () => {
    this.isScroll = false
  }

  @action
  setIsScrollPos = () => {
    this.isScrollPos = true
  }

  @action
  setIsNotScrollPos = () => {
    this.isScrollPos = false
  }

  @action
  setIsNotFocus = () => {
    this.isFocus = false
  }

  @action
  setAvtoplay = () => {
    this.avtoplay = true
  }

  @action
  setNotAvtoplay = () => {
    this.avtoplay = false
  }

  @action
  setWindowWidth = (width: number) => {
    this.windowWidth = width

    if (this.currentScreenId >= this.screensData.length - 1)
      this.currentScreenId = this.screensData.length - 1
  }

  @action
  setWindowHeight = (height: number) => {
    this.windowHeight = height
  }

  @action
  setTouchStartY = (sY: number) => {
    this.touchStartY = sY
  }

  @action
  setTouchStartX = (sX: number) => {
    this.touchStartX = sX
  }
  @action
  setCurrentScreenId = (id: number) => {
    if (this.isPromolink) {
      this.currentScreenId = id > 0 ? id + 1 : 0
    } else {
      this.currentScreenId = id
    }
  }

  @action
  scrollUp = () => {
    if (this.isFocus) {
      if (this.hold === false) {
        this.hold = true
        setTimeout(() => {
          this.hold = false
        }, 1000)
      }
    }
    if (this.isScroll) {
      if (this.hold === false) {
        this.hold = true
        setTimeout(() => {
          this.hold = false
        }, 1000)
      }
    }
    if (this.isScrollPos) {
      if (this.hold === false) {
        this.hold = true
        setTimeout(() => {
          this.hold = false
        }, 1000)
      }
    }
    if (this.hold === false) {
      this.hold = true
      this.currentScreenId =
        this.currentScreenId === this.screensData.length - 1
          ? this.currentScreenId
          : this.currentScreenId + 1
      setTimeout(() => {
        this.hold = false
      }, 1000)
    }
  }

  @action
  scrollDown = () => {
    if (this.isFocus) {
      if (this.hold === false) {
        this.hold = true
        setTimeout(() => {
          this.hold = false
        }, 1000)
      }
    }
    if (this.isScroll) {
      if (this.hold === false) {
        this.hold = true
        setTimeout(() => {
          this.hold = false
        }, 1000)
      }
    }
    if (this.isScrollPos) {
      if (this.hold === false) {
        this.hold = true
        setTimeout(() => {
          this.hold = false
        }, 1000)
      }
    }
    if (this.hold === false) {
      this.hold = true
      this.currentScreenId =
        this.currentScreenId === 0
          ? this.currentScreenId
          : this.currentScreenId - 1
      setTimeout(() => {
        this.hold = false
      }, 1000)
    }
  }

  goToUrl(url: string) {
    const a = document.createElement('a')
    if (!a.click) {
      //for IE
      window.location.href = url
      return
    }
    a.setAttribute('href', url)
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
  }

  constructor() {
    makeAutoObservable(this)
    this.setWindowWidth(window.innerWidth)
    this.setWindowHeight(window.innerHeight)

    this.log()

    when(
      () => this.computeAction === Actions.redirectToAltDomainPromo,
      () => {
        this.goToUrl(this.altDomainPromoLink)
      },
    )
  }
}

export default new Store()
