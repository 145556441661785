import React, { Component } from 'react'
import ScreensComponent from 'components/screensComponent/ScreensComponent'
import { inject, observer } from 'mobx-react'
import { Store } from 'stores'
import './styles.css'
interface IInjectedProps {
  store: Store
}



@inject('store')
@observer
class ScreenshotsPage extends Component {
  render() {
    const {  meditation } = (this
      .props as IInjectedProps).store
    return (
      <div className="panel" id="screenshots">
        <div className="panel-text ">
          <div className="screenshots-item">
            <ScreensComponent singlePage={true} />
          </div>
          <div>
            <h2>Screenshots</h2>
            <p>
              {meditation.install}
            </p>
            <div className="wrap-btn">
              <a
                rel="noreferrer"
                className="btn-form"
                target="_blank"
                href="https://apps.apple.com/us/app/my-energy-stream/id1498762389"
              >
                <div className="btn-form-wrap">
                  <svg
                    width="21"
                    height="26"
                    viewBox="0 0 21 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.9624 25.831C3.15194 25.8091 0 18.0306 0 14.0693C0 7.59836 4.85427 6.18171 6.72507 6.18171C7.56816 6.18171 8.46841 6.51279 9.26244 6.80577C9.8177 7.01014 10.392 7.22112 10.7113 7.22112C10.9025 7.22112 11.353 7.04165 11.7508 6.88418C12.599 6.5465 13.6545 6.1268 14.8836 6.1268C14.8858 6.1268 14.8888 6.1268 14.8909 6.1268C15.8087 6.1268 18.5915 6.32822 20.2646 8.84069L20.6565 9.4296L20.0925 9.8552C19.2867 10.4632 17.8166 11.5722 17.8166 13.769C17.8166 16.3708 19.4815 17.3714 20.2814 17.8527C20.6345 18.0651 21 18.2841 21 18.7632C21 19.0759 18.5036 25.7922 14.8785 25.7922C13.9914 25.7922 13.3644 25.5256 12.8114 25.2905C12.2518 25.0524 11.769 24.8473 10.9713 24.8473C10.567 24.8473 10.0557 25.0385 9.51437 25.2414C8.77464 25.5175 7.93735 25.831 6.9873 25.831H6.9624Z"
                      fill="currentColor"
                    />
                    <path
                      d="M15.2677 0.228271C15.3621 3.63244 12.9276 5.99409 10.496 5.84597C10.0953 3.12933 12.9273 0.228271 15.2677 0.228271Z"
                      fill="currentColor"
                    />
                  </svg>
                  <div className="btn-form-wrap-img">
                    <div className="download">Download on the</div>
                    <div>App Store</div>
                  </div>
                </div>
              </a>
              <a
                rel="noreferrer"
                className="btn-form"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.my_enegry_stream.app"
              >
                <div className="btn-form-wrap">
                  <svg
                    width="24"
                    height="26"
                    viewBox="0 0 24 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.6945 0.407398C3.71233 -0.143445 2.55915 -0.130709 1.58325 0.415358L13.021 11.1218L16.8634 7.22289L4.6945 0.407398Z"
                      fill="currentColor"
                    />
                    <path
                      d="M0.439309 1.51068C0.160034 1.97396 0 2.50411 0 3.0661V22.4029C0 22.9474 0.145913 23.4664 0.409499 23.9201L11.9115 12.2489L0.439309 1.51068Z"
                      fill="currentColor"
                    />
                    <path
                      d="M21.9577 10.0758L18.2926 8.02368L14.1741 12.2012L19.2214 16.9247L21.9593 15.3916C22.9446 14.8376 23.5345 13.8442 23.5345 12.7329C23.5329 11.6217 22.9446 10.6282 21.9577 10.0758Z"
                      fill="currentColor"
                    />
                    <path
                      d="M13.0648 13.3283L1.53137 25.0314C2.03187 25.3211 2.58101 25.474 3.13328 25.474C3.66673 25.474 4.20331 25.337 4.6944 25.0616L17.7748 17.7367L13.0648 13.3283Z"
                      fill="currentColor"
                    />
                  </svg>
                  <div className="btn-form-wrap-img">
                    <div className="download">Download on the</div>
                    <div>Google play</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScreenshotsPage
