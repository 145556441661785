import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Carousel from 'react-bootstrap/Carousel'
import { Store } from 'stores'

import './styles.css'
interface IInjectedProps {
  store: Store
}


@inject('store')
@observer
class RewiewsComponent extends Component {

  render() {
    const { reviewsText, meditation } = (this.props as IInjectedProps).store
    return (
      <div id="rewiews" className="panel position-relative">
        <div className="panel-text ">
          <h2>
            {meditation.rewiews}
          </h2>
          <div className="review-wrap">
            <Carousel controls={false} interval={3000}>
              {reviewsText.map((element, index: number) => {
                return (
                  <Carousel.Item key={index}>
                    <div className="d-flex flex-row justify-content-start">
                      <div className="review-name">
                        <img src={element.img} alt="" />
                        <p>{element.name}</p>
                      </div>

                      <div className="stars">
                        <p>{element.item}</p>
                        <div className="d-flex flex-row">
                          <svg
                            className="stars-item"
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 0.969678L9.54029 4.35995L9.64952 4.54144L9.85588 4.58924L13.7107 5.48202L11.1169 8.47011L10.978 8.63007L10.9963 8.8411L11.3384 12.7831L7.69504 11.2396L7.5 11.157L7.30496 11.2396L3.66157 12.7831L4.00369 8.8411L4.02201 8.63007L3.88315 8.47011L1.28929 5.48202L5.14412 4.58924L5.35048 4.54144L5.45971 4.35995L7.5 0.969678Z"
                              fill="#F0C175"
                              stroke="#FDD89B"
                            />
                          </svg>
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 0.969678L9.54029 4.35995L9.64952 4.54144L9.85588 4.58924L13.7107 5.48202L11.1169 8.47011L10.978 8.63007L10.9963 8.8411L11.3384 12.7831L7.69504 11.2396L7.5 11.157L7.30496 11.2396L3.66157 12.7831L4.00369 8.8411L4.02201 8.63007L3.88315 8.47011L1.28929 5.48202L5.14412 4.58924L5.35048 4.54144L5.45971 4.35995L7.5 0.969678Z"
                              fill="#F0C175"
                              stroke="#FDD89B"
                            />
                          </svg>
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 0.969678L9.54029 4.35995L9.64952 4.54144L9.85588 4.58924L13.7107 5.48202L11.1169 8.47011L10.978 8.63007L10.9963 8.8411L11.3384 12.7831L7.69504 11.2396L7.5 11.157L7.30496 11.2396L3.66157 12.7831L4.00369 8.8411L4.02201 8.63007L3.88315 8.47011L1.28929 5.48202L5.14412 4.58924L5.35048 4.54144L5.45971 4.35995L7.5 0.969678Z"
                              fill="#F0C175"
                              stroke="#FDD89B"
                            />
                          </svg>
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 0.969678L9.54029 4.35995L9.64952 4.54144L9.85588 4.58924L13.7107 5.48202L11.1169 8.47011L10.978 8.63007L10.9963 8.8411L11.3384 12.7831L7.69504 11.2396L7.5 11.157L7.30496 11.2396L3.66157 12.7831L4.00369 8.8411L4.02201 8.63007L3.88315 8.47011L1.28929 5.48202L5.14412 4.58924L5.35048 4.54144L5.45971 4.35995L7.5 0.969678Z"
                              fill="#F0C175"
                              stroke="#FDD89B"
                            />
                          </svg>
                          <svg
                            width="15"
                            height="14"
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 0.969678L9.54029 4.35995L9.64952 4.54144L9.85588 4.58924L13.7107 5.48202L11.1169 8.47011L10.978 8.63007L10.9963 8.8411L11.3384 12.7831L7.69504 11.2396L7.5 11.157L7.30496 11.2396L3.66157 12.7831L4.00369 8.8411L4.02201 8.63007L3.88315 8.47011L1.28929 5.48202L5.14412 4.58924L5.35048 4.54144L5.45971 4.35995L7.5 0.969678Z"
                              fill="#F0C175"
                              stroke="#FDD89B"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}

export default RewiewsComponent
