import React from 'react'

import interfaces from 'interfaces'
import Meditation from 'components/screens/Meditation'
import Possibility from 'components/screens/Possibility'
import FormComponent from 'components/screens/FormComponent'
import Reviews from 'components/screens/Reviews'
import ScreenshotsPage from 'components/screens/ScreenshotsPage'

import './styles.css'
import { ScreensLabel } from 'interfaces/Interfaces'
import store from 'stores/store'
import Promo from './Promo'
import PromoStore from './PromoStore'

export const images = [
  '/img/screen1.png',
  '/img/screen2.png',
  '/img/screen3.png',
  '/img/screen4.png',
  '/img/screen5.png',
  '/img/screen6.png',
]

export const screensLabelRU = (width: number): ScreensLabel[] => {
  const screens = [
    {
      label: 'Главная',
      id: 'meditation',
    },
    {
      label: 'Возможности',
      id: 'possibility',
    },
    {
      label: 'Отзывы',
      id: 'rewiews',
    },
    {
      label: 'Связаться',
      id: 'contact',
    },
  ]
  const screenshotsPage = {
    label: 'Скриншоты',
    id: 'screenshots',
  }

  if (width < 1201) screens.push(screenshotsPage)
  return screens
}

export const screensLabelPromoRU: ScreensLabel = {
  label: 'Промокод',
  id: 'promocode',
}

export const screensLabelEN = (width: number): ScreensLabel[] => {
  const screens = [
    {
      label: 'Home',
      id: 'meditation',
    },
    {
      label: 'Opportunities',
      id: 'possibility',
    },
    {
      label: 'Feedback',
      id: 'rewiews',
    },
    {
      label: 'Contact us',
      id: 'contact',
    },
  ]
  const screenshotsPage = {
    label: 'Screenshots',
    id: 'screenshots',
  }

  if (width < 1201) screens.push(screenshotsPage)
  return screens
}

export const screensLabelPromoEN: ScreensLabel = {
  label: 'Promocode',
  id: 'promocode',
}

export const screensData = (width: number): interfaces.Screen[] => {
  const screens = [
    {
      component: <Meditation />,
      title: 'Погрузись в мир медитаций с приложением "My Energy Stream"',
      label: 'Главная',
      labelEN: 'Home',
      id: 'meditation',
    },

    {
      component: <Possibility />,
      title: 'Возможности',
      label: 'Возможности',
      labelEN: 'Opportunities',
      id: 'possibility',
    },
    {
      component: <Reviews />,
      title: 'Отзывы пользователей',
      label: 'Отзывы',
      labelEN: 'Feedback',
      id: 'rewiews',
    },
    {
      component: <FormComponent />,
      title: 'Связаться с нами',
      label: 'Связаться',
      labelEN: 'Contact us',
      id: 'contact',
    },
  ]
  const screenshotsPage = {
    component: <ScreenshotsPage />,
    title: 'Скриншоты',
    label: 'Скриншоты',
    labelEN: 'Screenshots',
    id: 'screenshots',
  }

  if (width < 1201) screens.push(screenshotsPage)
  return screens
}

export const PromoScreen = {
  component: <Promo />,
  title: 'Промокод',
  label: 'Промокод',
  labelEN: 'Promocode',
  id: 'promocode',
}

export const PromoStoreScreen = {
  component: <PromoStore />,
  title: 'Промокод',
  label: 'Промокод',
  labelEN: 'Promocode',
  id: 'promocode',
}
